import React, { useState } from "react";
import { createProduct } from "../../../api/PupilApi";
import { toast, Toast } from "react-hot-toast";

const AddToProduct = () => {
  // Initial data for the form
  const initialData = {
    id: 0,
    category: "",
    productName: "",
    price: "",
    weight: "",
    wastage: "",
    des: "",
    email: "orgData@gmail.com",
    stockType: "",
    designName: "",
    onboardDate: "",
    goldRateWhileOnboard: "",
    pgjWastage: ""
  };

  const [formData, setFormData] = useState(initialData);
  const [imageFiles, setImageFiles] = useState([null, null, null]);
  const [videoFile, setVideoFile] = useState(null);
  const [error, setError] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle image file changes
  const handleImageChange = (index, e) => {
    const updatedImageFiles = [...imageFiles];
    updatedImageFiles[index] = e.target.files[0];
    setImageFiles(updatedImageFiles);
  };

  // Handle video file changes
  const handleVideoChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");


    // // Validate input data
    // if (imageFiles.some((file) => file === null)) {
    //   setError("Please upload all 3 images.");
    //   return;
    // }
    // const request = new FormData();
    // request.append("images", imageFiles.map((file) => (file ? URL.createObjectURL(file) : null)))
    // request.append("video", videoFile ? URL.createObjectURL(videoFile) : null)
    // request.append("productRequest", ...formData);
    // Prepare JSON data
    const jsonData = {
      ...formData, // Add all form data fields
      // images: imageFiles.map((file) => (file ? URL.createObjectURL(file) : null)), // Convert files to temporary URLs
      // video: videoFile ? URL.createObjectURL(videoFile) : null, // Convert video to temporary URL
    };

    // Debugging output
    //   console.log("JSON data to send:", jsonData);
    //  alert("JSON data to send:", +JSON.stringify(jsonData));
    createProduct(jsonData)
      .then((res) => {
        toast.success(res.data.message);
        // alert("data" + JSON.stringify(res.data));


      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
          toast.error("error");
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
          toast.error("error");
        }

        console.log(error);
      });

    // POST request to API
    //   const response = await fetch("/api/products", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(jsonData), // Send JSON data
    //   });

    //   if (response.ok) {
    //     alert("Product created successfully!");
    //     setFormData(initialData);
    //     setImageFiles([null, null, null]);
    //     setVideoFile(null);
    //   } else {
    //     const errorMessage = await response.text();
    //     setError(errorMessage || "Failed to create product.");
    //   }
    //  catch (err) {
    //   setError("An unexpected error occurred. Please try again.");
    //   console.error("Error:", err);
    // }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md space-y-6"
    >
      <h1 className="text-2xl font-bold text-center text-gray-800">Add New Product</h1>

      {error && (
        <div className="text-red-600 text-sm text-center bg-red-100 p-2 rounded-md">
          {error}
        </div>
      )}

      {/* Product ID (read-only) */}
      <div className="flex flex-col">
        <label htmlFor="_id" className="text-sm font-medium text-gray-600">Product ID</label>
        <input
          type="number"
          id="id"
          name="id"
          value={formData.id}
          onChange={handleChange}
          className="mt-1 p-2 border rounded-md shadow-sm"

        />
      </div>

      {["category", "productName", "price", "weight", "wastage", "des", "stockType", "onboardDate", "designName", "goldRateWhileOnboard", "pgjWastage"].map((field) => (
        <div key={field} className="flex flex-col">
          <label
            htmlFor={field}
            className="text-sm font-medium text-gray-600 capitalize"
          >
            {field.replace(/([A-Z])/g, " $1")}
          </label>
          {field === "category" ? (
            <select
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md shadow-sm"
            >
              <option value="" disabled>
                Select a category
              </option>
              <option value="Necklace">Necklace</option>
              <option value="Earring">Earring</option>
              <option value="Bangle">Bangle</option>
              <option value="Ring">Ring</option>
              <option value="Dollar">Dollar</option>
              <option value="Chain">Chain</option>
              <option value="Stud">Stud</option>
              <option value="BombayNeckles">BombayNeckles</option>
              <option value="Machine chain">Machine chain</option>
              <option value="Braslet">Braslet</option>
              <option value="FancyDollar">FancyDollar</option>
              <option value="JimikiDropsEaringStuds">JimikiDropsEaringStuds</option>
              <option value="FancyStuds">FancyStuds</option>

            </select>
          ) : (
            <>
             {field === "stockType" ? (
            <select
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md shadow-sm"
            >
              <option value="" disabled>
                Select a stockType
              </option>
              <option value="Inshop">Inshop</option>
              <option value="Onlyview">Onlyview</option>
            </select>
          ) : (
            <>
             {field === "designName" ? (
            <select
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md shadow-sm"
            >
              <option value="" disabled>
                Select a designName
              </option>
              <option value="Unique">Unique</option>
              <option value="Repeated">Repeated</option>
            </select>
          ) : (

            <>
            {field === "onboardDate" ? (
           <input
           type="date"
           id={field}
           name={field}
           value={formData[field]}
           onChange={handleChange}
           className="mt-1 p-2 border rounded-md shadow-sm"
         />
         ) : (
           <input
             type={field === "des" ? "textarea" : "text"}
             id={field}
             name={field}
             value={formData[field]}
             onChange={handleChange}
             className="mt-1 p-2 border rounded-md shadow-sm"
           />
         )}
           </>
          )}
            </>
          )}
            </>
          )}
        </div>
      ))}

      {/* Video upload 
      <div className="flex flex-col">
        <label htmlFor="video" className="text-sm font-medium text-gray-600">
          Upload Video
        </label>
        <input
          type="file"
          id="video"
          accept="video/*"
          onChange={handleVideoChange}
          className="mt-1 p-2 border rounded-md shadow-sm"
        />
      </div>
      */}

      {/* Image uploads 
      <div className="space-y-2">
        <label className="text-sm font-medium text-gray-600">Upload 3 Images</label>
        {imageFiles.map((file, index) => (
          <div key={index}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(index, e)}
              className="p-2 border rounded-md shadow-sm"
            />
            {file && <p className="text-sm text-gray-500 mt-1">{file.name}</p>}
          </div>
        ))}
      </div>
      */}

      {/* Submit button */}
      <div className="flex justify-center">
        <button
          type="submit"
          className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700"
        >
          Create Product
        </button>
      </div>
    </form>
  );
}
export default AddToProduct;