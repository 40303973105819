import React, { useState } from "react";
import { jsPDF } from "jspdf";


function DownloadPopup({ isOpen, onClose, onSubmit, productDetails }) {
    const [formData, setFormData] = useState({
      gram: "",
      wastage: "",
      goldRate: "",
      deliveryCharges: "",
      gst: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleGeneratePDF = () => {
      const doc = new jsPDF();
  
      // Add title
      doc.setFontSize(18);
      doc.text("Invoice Details", 10, 10);
  
      // Add product details
      doc.setFontSize(12);
      doc.text(`Product: ${productDetails.productName}`, 10, 20);
  
      // Add user-entered details
      doc.text(`Gram: ${formData.gram}`, 10, 30);
      doc.text(`Wastage: ${formData.wastage}`, 10, 40);
      doc.text(`Gold Rate: ${formData.goldRate}`, 10, 50);
      doc.text(`Delivery Charges: ${formData.deliveryCharges}`, 10, 60);
      doc.text(`GST: ${formData.gst}`, 10, 70);
  
      // Save the PDF
      doc.save("invoice.pdf");
    };
  
    const handleSubmit = () => {
      onSubmit({ ...formData, productDetails });
      handleGeneratePDF();
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4">
        <div className="bg-white rounded-lg p-4 sm:p-6 md:p-8 w-full max-w-sm sm:max-w-md md:max-w-lg shadow-lg">
          <h3 className="text-lg md:text-xl font-semibold text-gray-700 mb-4 text-center">
            Enter Invoice Details for Product: {productDetails.productName}
          </h3>
  
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-600">Gram:</span>
              <input
                type="number"
                name="gram"
                value={formData.gram}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </label>
  
            <label className="block">
              <span className="text-gray-600">Wastage:</span>
              <input
                type="number"
                name="wastage"
                value={formData.wastage}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </label>
  
            <label className="block">
              <span className="text-gray-600">Today's Gold Rate (24 Carat):</span>
              <input
                type="number"
                name="goldRate"
                value={formData.goldRate}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </label>
  
            <label className="block">
              <span className="text-gray-600">Delivery Charges:</span>
              <input
                type="number"
                name="deliveryCharges"
                value={formData.deliveryCharges}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </label>
  
            <label className="block">
              <span className="text-gray-600">GST (3%):</span>
              <input
                type="number"
                name="gst"
                value={formData.gst}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </label>
          </div>
  
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3 mt-6">
            <button
              onClick={handleSubmit}
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md shadow-sm w-full sm:w-auto"
            >
              Download Invoice
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md shadow-sm w-full sm:w-auto"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

export default DownloadPopup;
