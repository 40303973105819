import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import Adminsignin from "./pages/Account/Adminsignin";
import Terms from "./pages/Account/Terms";
import Forgotpassword from "./pages/Account/Forgotpassword";
import MobileSignIn from "./pages/Account/MobileSignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Chit from "./pages/Chit/Chit";
import PlanSuccess from "./pages/Chit/PlanSuccess";
import Plans from "./pages/Chit/Plans";
import GoldLoan from "./pages/GoldLoan/GoldLoan";
import GoldLoanSuccess from "./pages/GoldLoan/GoldLoanSuccess";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import SilverPrice from "./components/home/Header/SilverPrice";
import PrivacyPolicy from "./pages/Account/PrivacyPolicy";
import EmailOTPValidation from "./pages/Account/EmailOTPValidation";
import OrderSuccess from "./pages/Cart/OrderSuccess";

import './App.css';
import AdminTab from "./pages/Admin/AdminTab";
import AdminViewOrderList from "./pages/Admin/AdminViewOrderList";
import AdminViewProductList from "./pages/Admin/AdminViewProductList";
import AdminViewPlanList from "./pages/Admin/AdminViewPlanList";
import AdminViewPlanInvoiceList from './pages/Admin/AdminViewPlanInvoiceList'
import FixedDepositCalculator from "./pages/Fixeddeposit/FixedDeposit";
import FixedDeposit from "./pages/Fixeddeposit/FixedDeposit";
import FixedDepositSuccess from "./pages/Fixeddeposit/FixedDepositSuccess";
import WishList from "./pages/WishList/WishList";
import DownloadPopup from "./pages/Admin/DownloadPopup";
import AddToProduct from "./pages/Admin/Product/AddToProduct";




const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/orderSuccess" element={<OrderSuccess />}></Route>
        <Route path="/goldloansucess" element={<GoldLoanSuccess />}></Route>
        <Route path="/fixeddepositsucess" element={<FixedDepositSuccess />}></Route>
        <Route path="/planSuccess" element={<PlanSuccess/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/SilverPrice" element={<SilverPrice />}></Route>
        <Route path="/goldloan" element={<GoldLoan />}></Route>
        <Route path="/fixeddeposit" element={<FixedDeposit/>}></Route>
        
        <Route path="/emailotpvalidation" element={<EmailOTPValidation />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/chit" element={<Chit />}></Route>
        <Route path="/plans" element={<Plans />}></Route>
        <Route path="/Adminsignin" element={<Adminsignin />}></Route>
        <Route path="/adminDashboard" element={<AdminTab />}></Route>
        <Route path="/product" element={<AddToProduct />}></Route>
        <Route path="/viewOrderList" element={<AdminViewOrderList />}></Route>
        <Route path="/viewProductList" element={<AdminViewProductList />}></Route>
        <Route path="/downloadPopup" element={<DownloadPopup />}></Route>
        <Route path="/viewPlanList" element={<AdminViewPlanList />}></Route>
        <Route path="/viewPlanInvoiceList" element={<AdminViewPlanInvoiceList />}></Route>
        
        <Route path="/Forgotpassword" element={<Forgotpassword />}></Route>
        <Route path="/Terms" element={<Terms />}></Route>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
        

        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/wishlist" element={<WishList />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
      </Route>
      <Route path="/signup/:message" element={<SignUp />}></Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      
      
     
     
      <Route path="/Forgotpassword" element={<Forgotpassword />}></Route>
      <Route path="/Terms" element={<Terms />}></Route>
      <Route path="/MobileSignIn" element={<MobileSignIn />}></Route>
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
