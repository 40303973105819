import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { useState, useEffect, React } from "react";
import { Link } from "react-router-dom";
import "./grid.css";
import { toast, Toast } from "react-hot-toast";
import { getGvgOrder } from "../../api/PupilApi";
import { IoCloudDownload } from "react-icons/io5";

function AdminDashboardOrder() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };

  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const toolbarOptions = ["Search"];
  const selectionsettings = { persistSelection: true };
  const validationRules = { required: true };
  const orderidRules = { required: true, number: true };
  const pageSettings = { pageCount: 5 };
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };

  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setLoading(true);
    getGvgOrder()
      .then((res) => {
        toast.success(res.data.message);
       // alert("data" + JSON.stringify(res.data));
       
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
          toast.error("error");
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
          toast.error("error");
        }
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };
  const actionTemplate = (props) => {
    return (
      <Link
        to={{
          pathname: "/viewOrderList",
        }}
        state={{
          name: props.name,
          email: props.email,
          orderList: props.orderList,
        }}
        exact
      >
        <button
          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
          type="button"
        >
          {" "}
          <span>
            <IoCloudDownload />
          </span>{" "}
        </button>
      </Link>
    );
  };

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          enableHover={true}
          allowPaging
          pageSettings={{ pageCount: 5 }}
          selectionSettings={selectionSettings}
          toolbar={toolbarOptions}
          editSettings={editSettings}
          allowFiltering={false}
          allowGrouping={true}
          allowPdfExport={true}
          allowSorting
        >
          <ColumnsDirective>
            <ColumnDirective
              field="name"
              headerText="Name"
              width="150"
              textAlign="Center"
              //validationRules={orderidRules}
              isPrimaryKey={true}
            ></ColumnDirective>
            <ColumnDirective
              field="email"
              headerText="Email"
              width="150"
              textAlign="Center"
              // validationRules={validationRules}
            ></ColumnDirective>
            <ColumnDirective
              field="mobile"
              headerText="Mobile No"
              width="150"
              //format="C2"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="orderList"
              headerText="Order List"
              //format="yMd"
              width="150"
              textAlign="Center"
              template={actionTemplate}
            ></ColumnDirective>
            {/* <ColumnDirective
              field="paymentStatus"
              headerText="Payment Status"
              width="150"
              textAlign="Center"
            ></ColumnDirective> */}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}
export default AdminDashboardOrder;
