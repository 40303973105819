import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./grid.css";
import { toast, Toast } from "react-hot-toast";
import {
  getGvgData,
  getGvgOrder,
  retrieveGvgOrder,
} from "../../api/PupilApi";
import { IoClose, IoCloudDownload } from "react-icons/io5";

function AdminViewOrderList() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, email, orderList } = location.state; // constrcut as planData
  //alert("name"+JSON.stringify(name));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const toolbarOptions = ["Add", "Edit", "Delete"];

  const validationRules = { required: true };
  const orderidRules = { required: true, number: true };
  const pageSettings = { pageCount: 5 };

  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setLoading(true);
    retrieveGvgOrder(email)
      .then((res) => {
        toast.success(res.data.message);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
          toast.error("error");
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
          toast.error("error");
        }
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };
  const actionTemplate = (props) => {
    
    return (
      <Link
        to={{
          pathname: "/viewProductList",
        }}
        state={{
          name: name,
          email: email,
          productList: props.productList,
          order_id: props.order_id,

        }}
        exact
      >
        <button
          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
          type="button"
        >
          {" "}
          <span>
            <IoCloudDownload />
          </span>{" "}
        </button>
      </Link>
    );
  };
  const handleClickBack = () => {
    navigate("/adminDashboard");
  };

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
      <div className="flex items-center mb-3">
        <h2 className="name pr-3 font-medium">Name:<span className="bg-dark font-semibold">{name}</span></h2>
        <button
          className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          onClick={() => handleClickBack()}
        >
          <span>back</span>
        </button>
        </div>
        
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowPaging={true}
          //editSettings={editSettings}
          pageSettings={pageSettings}
          //actionComplete={actionComplete.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="orderStatus"
              headerText="Order Status"
              width="150"
              textAlign="Center"
              validationRules={orderidRules}
              isPrimaryKey={true}
            ></ColumnDirective>
            <ColumnDirective
              field="order_id"
              headerText="Order Id"
              width="150"
              textAlign="Center"
              validationRules={validationRules}
            ></ColumnDirective>
            <ColumnDirective
              field="shippingCharge"
              headerText="Shipping charge"
              width="150"
              //format="C2"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="totalCost"
              headerText="Total Cost"
              //format="yMd"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="productlist"
              headerText="Product List"
              //format="yMd"
              width="150"
              textAlign="Center"
              template={actionTemplate}
            ></ColumnDirective>
            {/*  <ColumnDirective
              field="plans"
              headerText="Plans"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            */}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}

export default AdminViewOrderList;
