import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";

import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import { earrings } from "../../../constants/index";
import { getNecklaceProductData } from "../../../api/Api";

const Earring = () => {
  const[loading, setLoading] = useState(false);
    const[data, setData] =useState([]);
    const loadData = () => {
        setLoading(true);
        getNecklaceProductData("orgData@gmail.com", "Earring")
          .then((res) => {
            const responseData = res.data;
      
            // Update `earrings` array with response data
            responseData.forEach((responseItem) => {
              const earringsItem = earrings.find(item => item._id === parseInt(responseItem._id));
              if (earringsItem) {
                earringsItem.des = responseItem.des; 
                earringsItem.price = responseItem.price;
                earringsItem.productName = responseItem.productName;
                earringsItem.wastage = responseItem.wastage;
                earringsItem.weight = responseItem.weight;
              }
            });
      
            setData(earrings);
            console.log("Updated data:", earrings);
          })
          .catch((error) => {
            if (error.message) {
              console.error("Error message:", error.message);
            } else {
              console.error("Sorry! Something went wrong.");
            }
          })
          .finally(() => {
            setLoading(false); // Ensures loading is turned off after the request completes
          });
      };
      useEffect(()=>{
        loadData();
      },[]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="Earrings" />
      <Slider {...settings}>
        {
          data?.map((product, index)=>{
            return (
              <div key={index}  className="px-2">
                <Product
                  _id={product._id}
                  img={product?.img}
                  category={product.category}
                  productName={product.productName}
                  price={product.price}
                  color={product.color}
                  size={product.size}
                  weight={product.weight}
                  badge={product.badge}
                  des={product.des}
                  video={product.video}
                  wastage={product.wastage}
                />
              </div>
            )
          })
        }
        
      </Slider>
    </div>
  );
};

export default Earring;
