import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";

import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import { necklace } from "../../../constants/index";
import { getNecklaceProductData } from "../../../api/Api";

const Necklace = () => {
  const[loading, setLoading] = useState(false);
  const[data, setData] =useState([]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const loadData = () => {
    setLoading(true);
    getNecklaceProductData("orgData@gmail.com","Necklace")
      .then((res) => {
        const responseData = res.data;
  
        // Update `necklace` array with response data
        responseData.forEach((responseItem) => {
          const necklaceItem = necklace.find(item => item._id === parseInt(responseItem._id));
          if (necklaceItem) {
            necklaceItem.des = responseItem.des; 
            necklaceItem.price = responseItem.price;
            necklaceItem.productName = responseItem.productName;
            necklaceItem.wastage = responseItem.wastage;
            necklaceItem.weight = responseItem.weight;
          }
        });
  
        setData(necklace);
        console.log("Updated data:", necklace);
      })
      .catch((error) => {
        if (error.message) {
          console.error("Error message:", error.message);
        } else {
          console.error("Sorry! Something went wrong.");
        }
      })
      .finally(() => {
        setLoading(false); // Ensures loading is turned off after the request completes
      });
  };
  useEffect(()=>{

    loadData();
// call backend with category
// you will get productlist json
// update the necklace data as per prodcut id
  },[]);
  return (
    <div className="w-full pb-16">
      <Heading heading="Necklace" />
      <Slider {...settings}>
        {
          data?.map((product, index)=>{
            return (
              <div key={index}  className="px-2">
                <Product
                  _id={product._id}
                  img={product?.img}
                  category={product.category}
                  productName={product.productName}
                  price={product.price}
                  color={product.color}
                  size={product.size}
                  weight={product.weight}
                  badge={product.badge}
                  des={product.des}
                  video={product.video}
                  wastage={product.wastage}
                />
              </div>
            )
          })
        }
        
      </Slider>
    </div>
  );
};

export default Necklace;
