import React, { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import { useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { saveInvoiceData } from "../../api/PupilApi";
import { logo } from "../../assets/images";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { useLocation } from "react-router-dom";
import "./grid.css";
const MySwal = withReactContent(Swal);

function DownloadPopup({ isOpen, onClose, onSubmit, email, order_id, productDetails }) {
  const [formData, setFormData] = useState({
    gram: "",
    wastage: "",
    goldRate: "",
    deliveryCharges: "",
    gst: "3",
  });

  const [totalCost, setTotalCost] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
   calculateTotal();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) return; // Ensure no negative inputs
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const calculateTotal = () => {
    const gramValue = parseFloat(formData.gram) || 0;
    const wastageValue = parseFloat(formData.wastage) || 0;
    const goldRateValue = parseFloat(formData.goldRate) || 0;
    const deliveryChargesValue = parseFloat(formData.deliveryCharges) || 0;
    const westageInMilliGram=(gramValue*wastageValue)/100;
    const weightIncludingWastage=westageInMilliGram+gramValue;
    const subtotal = (weightIncludingWastage * goldRateValue)/1000 + deliveryChargesValue;
    const gstValue = subtotal * (parseFloat(formData.gst) / 100);

    setTotalCost(subtotal + gstValue);
  };



  const generateRandomInvoiceNumber = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`; // Unique combination of current datetime
};

  const handleGeneratePDF = (paymentInvoiceData) => {
alert(JSON.stringify(paymentInvoiceData))
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

 

    const invoiceDate = new Date().toLocaleDateString();

    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10);

    const logoWidth = 40;
    const logoHeight = 50;
    const logoImage = logo; // Replace with actual logo path

    doc.addImage(logoImage, "PNG", 10, 10, logoWidth, logoHeight);

    doc.setFontSize(12);
    doc.text("44, MECHERI, Kukkalpatti, Salem, Tamil Nadu, 636453", pageWidth / 2, 35, {
      align: "center",
    });

    doc.text("GST: 33CTPPK9946M1ZQ, Phone: 04298-278160", pageWidth / 2, 42, {
      align: "center",
    });

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Details", pageWidth / 2, 55, { align: "center" });

    doc.setFontSize(12);
    doc.text(`Invoice Number: ${paymentInvoiceData.invoiceNumber}`, 10, 65);
    doc.text(`Date: ${invoiceDate}`, pageWidth - 60, 65);

   

    const tableRows = [
      ["Product", productDetails.productName],
      ["Gram", `${formData.gram || "0"} Milli grams`],
      ["Wastage", `${formData.wastage || "0"} %`],
      ["Gold Rate (22 Carat)", `Rs.${formData.goldRate || "0"}`],
      ["Delivery Charges", `Rs.${formData.deliveryCharges || "0"}`],
      ["Subtotal", `Rs.${paymentInvoiceData.costExcludingGstPay}`],
      ["GST", `Rs.${paymentInvoiceData.gstPercentage}`],
      [
        { content: "Total Cost With GST", styles: { textColor: [0, 128, 0], fontStyle: 'bold' } },
        { content: `Rs.${(paymentInvoiceData.costIncludingGstPay)}`, styles: { textColor: [0, 128, 0], fontStyle: 'bold' } },
      ],
      
      
    ];
    
    doc.autoTable({
      head: [["Description", "Value"]],
      body: tableRows,
      startY: 75,
    });
    

    doc.autoTable({
      head: [["Description", "Value"]],
      body: tableRows,
      startY: 75,
    });

    doc.setFontSize(10);
    doc.text(
      "This is a system-generated invoice.",
      pageWidth / 2,
      pageHeight - 10,
      { align: "center" }
    );

    doc.save("invoice.pdf");
  };

  const handleGeneratePDFForWithoutGST = (paymentInvoiceData) => {
    alert("sadfsdafdsf")
    const doc = new jsPDF();
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    const invoiceNumber = paymentInvoiceData.invoiceNumber;
    const invoiceDate = paymentInvoiceData.invoiceDate;
  
    // Draw a plain border
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10);
  
    // Add invoice title
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Details", pageWidth / 2, 20, { align: "center" });
  
    // Add basic invoice information
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${invoiceNumber}`, 10, 35);
    doc.text(`Date: ${invoiceDate}`, pageWidth - 60, 35);
  
    // Prepare table data
    const tableRows = [
      ["Gram", `${paymentInvoiceData.gram || "0"} Milli grams`],
      ["Wastage", `${paymentInvoiceData.wastage || "0"} %`],
      ["Gold Rate (22 Carat)", `Rs.${paymentInvoiceData.goldRatePerGram24Carot || "0"}`],
      ["Delivery Charges", `Rs.${paymentInvoiceData.deliveryCharge || "0"}`],
      ["Subtotal", `Rs.${paymentInvoiceData.costExcludingGstPay}`],
      [
        { content: "Total Cost Without GST", styles: { textColor: [255, 0, 0], fontStyle: 'bold' } },
        { content: `Rs.${paymentInvoiceData.costExcludingGstPay}`, styles: { textColor: [255, 0, 0], fontStyle: 'bold' } },
      ],
    ];
  
    // Add table to PDF
    doc.autoTable({
      head: [["Description", "Value"]],
      body: tableRows,
      startY: 45,
    });
  
    // Add footer
    doc.setFontSize(10);
    doc.text(
      "This is a system-generated invoice.",
      pageWidth / 2,
      pageHeight - 10,
      { align: "center" }
    );
  
    // Save PDF
    doc.save("invoice_without_gst.pdf");
  };
  
  const handleSubmit = async (productDetails) => {
    const newErrors = {};
  
    if (!formData.gram) newErrors.gram = "Gram value is required";
    if (!formData.goldRate) newErrors.goldRate = "Gold rate is required";
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    setErrors({});
  
    // Perform calculations
    const gramValue = parseFloat(formData.gram) || 0;
    const wastageValue = parseFloat(formData.wastage) || 0;
    const goldRateValue = parseFloat(formData.goldRate) || 0;
    const deliveryChargesValue = parseFloat(formData.deliveryCharges) || 0;
    const westageInMilliGram = (gramValue * wastageValue) / 100;
    const weightIncludingWastage = westageInMilliGram + gramValue;
    const subtotal = (weightIncludingWastage * goldRateValue) / 1000 + deliveryChargesValue;
    const gstValue = subtotal * (parseFloat(formData.gst) / 100);
    const totalCost = subtotal + gstValue;
  
    const invoiceDate = new Date().toLocaleDateString();
    
    // Prepare paymentInvoiceData object
    const paymentInvoiceData = {
      invoiceNumber:generateRandomInvoiceNumber(),
      order_id: order_id,
      email: email,
      transactionDetails: null,
      paymentStatus: "pending",
      costIncludingGstPay: `Rs.${totalCost.toFixed(2)}`,
      costExcludingGstPay: `Rs.${subtotal.toFixed(2)}`,
      gstPercentage: formData.gst,
      invoiceDate: invoiceDate,
      gram: gramValue,
      wastage: wastageValue,
      goldRatePerGram24Carot: goldRateValue,
      deliveryCharge: deliveryChargesValue,
      createdAt: new Date().toISOString(),
    };
    paymentInvoiceData.costIncludingGstPay = paymentInvoiceData.costIncludingGstPay.replace("Rs.", "").trim();
    paymentInvoiceData.costExcludingGstPay = paymentInvoiceData.costExcludingGstPay.replace("Rs.", "").trim();
  
    try {
      // Save invoice data to the backend
      await saveInvoiceData(paymentInvoiceData);
  
      // Generate PDF
      if (formData.gst===null || formData.gst === 0 || formData.gst === "0") {
       
        handleGeneratePDFForWithoutGST(paymentInvoiceData);
      } else {
        handleGeneratePDF(paymentInvoiceData);
      }
    } catch (error) {
      console.error("Failed to save invoice data:", error);
      alert("Failed to save invoice data. Please try again.");
    }
  };
  

  // const paymentInvoiceData={
  //   orderId:order_id,
  //   email:email,
  //   transactionDetails:null,
  //   paymentStatus:"pending",
  //   costIncludingGstPay:`Rs.${(subtotal + gstValue).toFixed(2)}`,
  //   costExcludingGstPay:`Rs.${subtotal.toFixed(2)}`,
  //   gstPercentage:"3",
  //   invoiceNumber:generateRandomInvoiceNumber,
  //   invoiceDate:invoiceDate,
  //   gram:gram,
  //   wastage:wastage,
  //   goldRatePerGram24Carot:goldRate,
  //   deliveryCharge:deliveryCharge,
  //   createdAt:""

  // }
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-11/12 max-w-lg p-6 space-y-4">
        <h2 className="text-xl font-bold text-center">Invoice for: {productDetails.productName}</h2>
        <form className="space-y-4">
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <label className="block text-gray-700 capitalize">{field}:</label>
              <input
                type="number"
                name={field}
                value={formData[field]}
                onChange={handleChange}
                className={`w-full border px-3 py-2 rounded ${
                  errors[field] ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors[field] && <p className="text-red-500 text-sm">{errors[field]}</p>}
            </div>
          ))}
          <div className="text-lg font-semibold text-gray-800">
            Total Cost: Rs.{totalCost.toFixed(2)}
          </div>
        </form>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Download Invoice
          </button>
        </div>
      </div>
    </div>
  );
}

function AdminViewProductList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, email, productList, order_id } = location.state;
  const toolbarOptions = ["Add", "Edit", "Delete"];
  const pageSettings = { pageCount: 5 };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handlePopupOpen = (product) => {
    setSelectedProduct(product);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setSelectedProduct(null);
  };

  const handlePopupSubmit = (details) => {
    console.log("Invoice Details Submitted:", details);
    // Trigger download or further processing here
  };

 

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <div className="flex items-center mb-3">
          <h2 className="name pr-3 font-medium">
            Name: <span className="bg-dark font-semibold">{name}</span>
          </h2>
          <Link
            to={{ pathname: "/viewOrderList" }}
            state={{ name: name, email: email }}
            exact
          >
            <button
              className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            >
              <span>Back</span>
            </button>
          </Link>
        </div>

        <GridComponent
          dataSource={productList}
          toolbar={toolbarOptions}
          allowPaging={true}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="_id"
              headerText="Product Id"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="category"
              headerText="Category"
              width="150"
              textAlign="Center"
              isPrimaryKey={true}
            ></ColumnDirective>
            <ColumnDirective
              field="size"
              headerText="Size"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="weight"
              headerText="Weight"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="Invoice"
              headerText="Invoice"
              width="150"
              textAlign="Center"
              template={(props) => (
                <button
                  onClick={() => handlePopupOpen(props)}
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Generate/update Invoice
                </button>
              )}
            ></ColumnDirective>
              <ColumnDirective
              field="Invoice"
              headerText="Invoice"
              width="150"
              textAlign="Center"
              template={(props) => (
                <button
                  onClick={() => handlePopupOpen(props)}
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  view/downlod Invoice
                </button>
              )}
            ></ColumnDirective>
            
            <ColumnDirective
              field="color"
              headerText="Color"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="des"
              headerText="Description"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="price"
              headerText="Price"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="productName"
              headerText="Product Name"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="quantity"
              headerText="Quantity"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          </ColumnsDirective>

          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>

        <DownloadPopup
          isOpen={isPopupOpen}
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
          productDetails={selectedProduct}
          email={email}
          order_id={order_id}
        />
      </div>
    </div>
  );
}

export default AdminViewProductList;
