// src/components/GoldLoanPage.js
import { React, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../contexts/StateContext';
import { saveGoldLoanDataBff } from '../../api/PupilApi';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import GoldLoanImage from '../../assets/images/goldloancontent.jpg';

const GoldLoanPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useContextSelector(StateContext, (state) => state.isLoggedIn);
  const [applliedFor, setAppllidFor] = useState("");
  const saveGoldLoanData = (e) => {

    //alert("onclick inside"+JSON.stringify(products));
    e.preventDefault();

    if (isLoggedIn) {



      // alert("before backend call"+JSON.stringify(orderRequest));
      saveGoldLoanDataBff("goldLoanRequest")
        .then((res) => {
          // alert("after backend call"+JSON.stringify(res));
          toast(res.message);
          navigate("/goldloansucess");
        })
        .catch((error) => {
          if (error.message) {
            toast(error.message);

          } else {
            toast("Sorry! Something went wrong. Please try again!");


          }
          // setShowLoading(false);
          console.log(error);
        });
    }
    else {

      navigate("/signup")

    }



  };
  return (
    <div className="container mx-auto mt-8 min-h-screen">
      <div className="row">
        {/* Left Side (Image) */}
        <div className="col-lg-6">
          <img
            src={GoldLoanImage}
            alt="Gold Loan"
            className="w-89 h-89 object-cover rounded-lg"
          />
        </div>

        {/* Middle Section (Button) */}
        <div className="col-lg-12 flex justify-center mt-6">
          <a
            onClick={saveGoldLoanData}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
          >
            Request
          </a>
        </div>

        {/* Right Side (Details) */}
        <div className="col-lg-6">
          <div className="ml-6 p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-3xl font-semibold mb-3">Gold Loan Details</h1>
            <p className="text-lg">
              GVG groups Gold Loan is the one-stop solution for all your financial needs.
              You can apply for a Gold Loan for various purposes such as agriculture,
              business expansion, child education, medical emergencies, etc.
              The process involves minimal documentation, quick disbursement, and ensures
              the complete safety of your gold.
            </p>
            <p className="text-lg mt-4">
              Features of GVG Groups Gold Loan:
              <ul className="list-disc pl-6">
                <li>Low-interest rates</li>
                <li>Flexible repayment options</li>
                <li>Quick approval process</li>
                <li>Secure and safe handling of your gold</li>
                <li>Locker Facility Available</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* Extra Card Highlight */}
      <div className="mt-6 mb-2 p-6 bg-blue-500 text-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-3">Special Offer!</h2>
        <p className="text-lg">
          Avail a special 1-gram gold loan up to Rs. 5200 with our exclusive limited-time offer.
          Quick approval, minimal documentation, and easy repayment options available.
        </p>
      </div>
    </div>
  );
};

export default GoldLoanPage;
