import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import YouTubeVideo from "../YouTubeVideo/YouTubeVideo";

const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState({ img: [], video: "" });
  const [mediaItems, setMediaItems] = useState([]); // Combine images and video
  const [selectedMedia, setSelectedMedia] = useState(""); // Track selected media

  useEffect(() => {
    if (location.state?.item) {
      const { img = [], video } = location.state.item;
      setProductInfo(location.state.item);
      //alert("video"+JSON.stringify(location.state.item))
      // Combine images and video into a single array
      const combinedMedia = [...img];
      if (video) combinedMedia.push(video);
      //alert("product info"+JSON.stringify(combinedMedia));
      setMediaItems(combinedMedia);
      setSelectedMedia(combinedMedia[0] || ""); // Default to the first media
      
    }
    setPrevLocation(location.pathname);
  }, [location]);
  const CustomArrow = ({ className, style, onClick, direction }) => {
    return (
      <div
        className={`${className} absolute top-1/2 transform -translate-y-1/2 ${
          direction === "left" ? "left-[-20px]" : "right-[-20px]"
        }`}
        style={{
         
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
        
        }}
        onClick={onClick}
      >
        {direction === "left" ? "<" : ">"}
      </div>
    );
  };
  
  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="left"  />,
    nextArrow: <CustomArrow direction="right" />,
  };

const isYouTubeLink = (url) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubeRegex.test(url);
};

const isImageFile = (url) => {
  const imageRegex = /\.(jpeg|jpg|gif|png|svg|webp)$/i;
  return imageRegex.test(url);
};

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="h-full md:mt-8 md:ml-5 items-center xl:col-span-2">
            {mediaItems.length > 0 ? (
              <Slider {...sliderSettings}>
                {mediaItems.map((media, index) => (
        <div key={index} className="media-item">
          {isYouTubeLink(media) ? (
            <YouTubeVideo link={media} />
          ) : isImageFile(media) ? (
            <img
              className="w-[352px] h-[456px] object-cover"
              src={media}
              alt={`Product Media ${index + 1}`}
            />
          ) : (
            <div className="text-center text-red-500">
              Unsupported media format
            </div>
          )}
        </div>
      ))}
              </Slider>
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-gray-200">
                No Media Available
              </div>
            )}
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
